<template>
    <div class='box'>
        <div class="video-box">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/psyhelp' }">自助资源</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ videoDetail.title }} </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="videoPlay">
                <div class="video-title">{{ videoDetail.title }}</div>
                <div class="video-header">
                    <p><span>讲师：{{ videoDetail.lecturer }}</span></p>
                    <p><span>观看次数：{{ videoDetail.viewCount }}</span></p>
                    <p><span>时间：{{ videoDetailCreatedAt }}</span></p>
                </div>
                <div class="video-play">
                    <div class="player-container">
                        <div id="artplayer" class="video-player-box">
                        </div>
                    </div>
                </div>
                <div class="tetailIntroduce" v-html="videoDetail.summary"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Artplayer from 'artplayer';
import { post, get } from '../utils/xajaxVideo'
import request from "../utils/request";
import { getItem } from '../utils/storage'
import VueCoreVideoPlayer from 'vue-core-video-player'

export default {
    data () {
        return {
            audio: {},
            videoDetail: {},
            playerOptions: {},
            videoDetailCreatedAt: '',
            url: '',
            id: '',
            artplayer: null,
            srcImgUrl: ''
        };
    },
    //接口 一进入页面就执行的  
    mounted () {
        this.id = this.$route.params.id
        this.getClassList()
    },
    //事件方法
    methods: {
        getClassList () {
            let data = {
                vid: this.id
            };
            request({
                url: "/smallvideo/detail",
                method: "post",
                data: data,
            }).then((res) => {
                this.videoDetail = res.data.detail
                this.url = res.data.detail.playUrl
                this.videoDetailCreatedAt = res.data.detail.duration
                this.srcImgUrl = res.data.detail.coverImg
                this.artplayer = new Artplayer({
                    container: '#artplayer',
                    url: res.data.detail.playUrl,
                    playbackRate: true,
                    setting: true, // 设置 （倍速）
                    fullscreen: true, //全屏
                    // fullscreenWeb: true, //网页全屏
                    pip: true, //画中画
                    autoplay: true, //自动播放
                    poster: res.data.detail.coverImg, //封面图
                    backdrop: true,//背景
                    theme: '#fff', //进度条颜色
                });

                this.artplayer.on('ready', (...args) => {
                    if (res.data.detail.mediaType == 2) {
                        console.log('触发', `url('${this.srcImgUrl}');`)
                        this.artplayer.video.style.background = `url(${this.srcImgUrl}) no-repeat`
                    }
                });
            })
            // get('/smallvideo/detail', { id: this.id })
            //     .then((res) => {
            //         console.log(res)

            //         this.videoDetail = res.data.detail
            //         this.url = res.data.detail.playUrl
            //         this.videoDetailCreatedAt = res.data.detail.duration
            //         this.srcImgUrl = res.data.detail.coverImg
            //         console.log(this.srcImgUrl)
            //         this.artplayer = new Artplayer({
            //             container: '#artplayer',
            //             url: res.data.detail.playUrl,
            //             playbackRate: true,
            //             setting: true, // 设置 （倍速）
            //             fullscreen: true, //全屏
            //             // fullscreenWeb: true, //网页全屏
            //             pip: true, //画中画
            //             autoplay: true, //自动播放
            //             poster: res.data.detail.coverImg, //封面图
            //             backdrop: true,//背景
            //             theme: '#fff', //进度条颜色
            //         });

            //         this.artplayer.on('ready', (...args) => {
            //             if(res.data.detail.mediaType == 2) {
            //                 console.log('触发', `url('${this.srcImgUrl}');`)
            //                 this.artplayer.video.style.background= `url(${this.srcImgUrl}) no-repeat`
            //             }
            //         });
            //     })
        },
    },
    //注册子组件
    components: {},
    computed: {},
    created () {
        // this.id = this.$route.params.id
        // this.getClassList()
    },
    //过滤器
    filters: {
        filtime (val) { },
    },
};
</script>

<style scoped lang="less">
.box {
    background: #eff4ff;
}

.video-box {
    width: 1200px;
    margin: 0 auto;
    min-height: 800px;
    padding-bottom: 72px;
}

.video-title {
    margin: 38px 0 24px;
    font-weight: 400;
    font-size: 28px;
    text-align: center;
}

.video-header {
    display: flex;
    font-size: 16px;
    color: #9b9da2;
    margin: 14px auto;
    width: 80%;
    justify-content: space-evenly;
}

// .video-header > p {
//   margin-right: 24px;
// }
// 视屏样式
/deep/ #vjs_video_536 {
    height: auto !important;
    //   width: 1200px !important;
    background: transparent;
}

/deep/ .video-js .vjs-big-play-button {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/deep/ .video-js .vjs-tech {
    width: 100%;
    height: auto;
    position: static;
}

/deep/ .vjs-poster {
    background: transparent;
    background-size: cover;
}

/deep/.video-js {
    width: 80%;
    margin: 0 auto;
    height: 100%;
}

.tetailIntroduce {
    font-size: 16px;
    // width: 80%;
    width: 1065px;
    margin: 32px auto 0px;
}

// 面包屑
.nav {
    padding-top: 60px;
}

/deep/.art-video {
    // background: url('{this.bgimage}') no-repeat !important;
    // background: url('https://xlingtong.oss-cn-beijing.aliyuncs.com/uploads/images/20221216/ee9296b47e4cb4d4147286ee206b0476.png') no-repeat;
    background-size: 1065px 600px !important;
    // background-size: 100% !important;
}

/deep/ .video-player-box-audio .vjs-has-started .vjs-poster {
    display: block !important;
}

/deep/.art-video-player {
    width: 1065px !important;
    height: 600px !important;
}
</style>
